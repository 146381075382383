import React from 'react'
import { Container } from 'reactstrap'
import CareerRequirements from '../components/CareerRequiremetns/CareerRequirements'
import Layout from '../components/layout/layout'
import { Helmet } from "react-helmet"



const JobDescriptionFullStackUS = () => {
    return (
        <div>
            <Helmet title='DNNae | Deep Learning JD' />
            <Layout
                containerStyle={{
                    position: 'relative'

                }}
                buttonStatus={false}
                selectedState=''
            >

                <Container
                    
                    style={{
                    maxWidth: '1920px'
                    }}
                >
                <CareerRequirements
                
                    
                    jobTitle='Deep Learning Engineer'
                    jobTypeAndPlace='Contract, San Francisco'
                    weAreLooking='We are looking for a Deep Learning Engineer who will partner with both the business and technical teams to innovate and deliver new features and enhancements for our product. You will create and/or modify complex solutions that meet the expectations of users. You will also have a thorough knowledge of the entire system and work with product, engineering and other teams in the organization to deliver the best experience to the users.'
                    responsibilitiesArray={[
                        "Develop intuitive functionality that can process different types of pdf/images documents and turns into machine-encoded text using the OCR, Machine, and Deep Learning techniques.",
                        "Write and review lines of programming code using a range of programming languages while keeping a close attention to details.",
                        "Have strong analytical skills to review systems and code to identify issues or areas for improvement.",
                        "Coordinate with other professionals in order to develop effective systems; so being a team player with good communication skills.",
                        "They also need written communication skills to document their research and prepare reports.",
                        "Have expertise in computer programming skills, strong mathematical skills and problem-solving skills.",
                    ]}
                    
                    whatYouNeedArray={[
                        "3+ years of experience, working in AI or similar domains.",
                        "Proficiency in particular python and usage of open-source libraries for machine learning scikit-learn/tensorflow/pytorch",
                        "Good knowledge of OCR libraries tesseract/abbey",
                        "Basic knowledge of document conversion libraries iText/PDFNet/LeadTools",
                        "Good concept of general-purpose programming languages",
                        "Knowledge of python web framework FLASK/ django",
                        "Good Knowledge of data structures and dealing with JSON/SQL",
                        "Basic knowledge of front-end technologies HTML/JavaScript/CSS and related web technologies",
                        "Familiarity with API development",
                        "To understand technical specifications and to convert them into feasible architectural solutions",
                        "Must have good analytical and problem-solving skills",
                    ]}
                >

                </CareerRequirements>

                </Container>
            </Layout>
        </div>
    )
}

export default JobDescriptionFullStackUS
